
import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { Pageniation as Table } from "@/utils/decorator";
import t from "@common/src/i18n/t";
const setting = namespace("setting");

@Component({})
@Table("loadListData")
export default class Permission extends Vue {
  @setting.Action getRoleList;

  keyword: string = "";
  staffListData: any[] = [
    {
      roleName: t("setting.engineer")
    }
  ];
  tableLoading: boolean = false;
  created() {
    this.loadListData();
  }
  handleEdit(row) {
    this.$router.push(`/setting/role-edit/${row.roleCode}`);
  }
  handleView(row) {
    this.$router.push(`/setting/role-view/${row.roleCode}`);
  }
  handleSearch() {
    this.mix_pageNum = 1;
    this.loadListData();
  }
  @Table.LoadDataMethod()
  loadListData() {
    return this.getRoleList({
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize,
      searchKey: this.keyword
    }).then(data => {
      this.staffListData = data.data.list;
      return data;
    });
  }
}
